<template>
  <div class="banner-manage base-container">
    <div class="header-mol">
      <div class="info">
        <h3 class="title">广告管理</h3>
        <p class="summary">
          为了保持页面的美观度，请尽量按照广告要求尺寸、比例发布相应广告
        </p>
      </div>
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="toggleEditDialog = true"
        >发布广告</el-button
      >
    </div>
    <div class="filter">
      <el-select
        class="select"
        v-model="queryData.status"
        placeholder="状态"
        @change="search"
        clearable
      >
        <el-option
          v-for="(item, key) in status"
          :key="key"
          :label="item"
          :value="key"
        ></el-option>
      </el-select>
      <el-cascader
        placeholder="广告位"
        class="select"
        clearable
        v-model="queryData.advertTypeId"
        :options="advertTypeTree"
        :props="{
          label: 'name',
          value: 'id',
          emitPath: false
        }"
        @change="search"
      ></el-cascader>
      <el-select
        class="select"
        v-model="queryData.device"
        placeholder="终端"
        @change="search"
        clearable
        v-if="!distributionFlag"
      >
        <el-option label="小程序" value="2"></el-option>
        <el-option label="APP" value="1"></el-option>
      </el-select>
    </div>
    <el-table border class="body" style="margin-top: 10px;" :data="list">
      <el-table-column label="广告图片" width="460px">
        <template slot-scope="scope">
          <div class="base-info">
            <el-image
              class="cover"
              fit="cover"
              :src="scope.row.image"
            ></el-image>
            <div class="text">
              <p class="name">
                {{ scope.row.title }}
              </p>
              <div class="sub">
                <p>排序号：{{ scope.row.sort }}</p>
                <p>广告位：{{ scope.row.advertTypeName }}</p>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="终端" prop="device" v-if="!distributionFlag">
        <template slot-scope="scope">
          {{ scope.row.device == 1 ? "房猫找房APP" : "小程序" }}
        </template>
      </el-table-column>
      <el-table-column label="链接" prop="url"></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <p :class="{ red: scope.row.status === 0 }">
            {{ status[scope.row.status] }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="创建">
        <template slot-scope="scope">
          <p>
            {{ scope.row.inUserName }}
          </p>
          <br />
          <p>
            {{ scope.row.inDate }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180px">
        <template slot-scope="scope">
          <el-button
            type="text"
            v-if="scope.row.status != 1"
            @click="changeAdvertStatus(scope.row.id, 1)"
            >发布</el-button
          >
          <el-button
            type="text"
            v-if="scope.row.status == 1"
            @click="changeAdvertStatus(scope.row.id, 0)"
            >下架</el-button
          >
          <el-button class="mlr10" type="text" @click="showEdit(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            v-if="scope.row.status != 1"
            title="确定删除该广告？"
            @onConfirm="deleteAdvert(scope.row.id)"
          >
            <el-button slot="reference" type="text">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <f-pagination
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="total"
      @change="getAdvertManageList"
    ></f-pagination>
    <el-dialog
      :close-on-click-modal="false"
      :title="formData.id ? '编辑广告' : '发布广告'"
      class="f-dialog"
      :visible.sync="toggleEditDialog"
      width="490px"
      :before-close="resetForm"
    >
      <el-form class="f-form" :model="formData" label-width="150px" ref="form">
        <el-form-item
          prop="advertTypeId"
          label="广告位："
          :rules="[$formRules.required()]"
        >
          <el-cascader
            class="W100"
            v-model="formData.advertTypeId"
            :options="advertTypeTree"
            :props="{
              label: 'name',
              value: 'id',
              emitPath: false
            }"
          ></el-cascader>
        </el-form-item>
        <el-form-item
          prop="device"
          label="上架终端"
          :rules="[$formRules.required()]"
          v-if="!distributionFlag"
        >
          <el-radio-group v-model="formData.device">
            <el-radio-button label="2">小程序</el-radio-button>
            <el-radio-button label="1">房猫找房APP</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          prop="title"
          label="标题："
          :rules="[$formRules.required()]"
        >
          <el-input
            v-model="formData.title"
            placeholder="请输入"
            maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="url"
          label="地址/路径："
          :rules="[$formRules.required()]"
        >
          <el-input v-model="formData.url" placeholder="请输入"></el-input>
          <p style="padding-top: 10px;">
            <a class="blue" :href="`${$OSS_URL}/doc/routeMaps.txt`"
              >《“地址/路径”填写指导》</a
            >
          </p>
        </el-form-item>
        <el-form-item
          prop="image"
          :rules="[$formRules.required()]"
          label="广告图片："
        >
          <p class="tips">支持jpg、png、gif格式，建议大小1M以内</p>
          <f-upload
            class="banner-uploader"
            :type="2"
            :limitSize="1"
            :default-file-list="[{ url: formData.image }]"
            @on-success="bannerImageUploadSuccess"
          ></f-upload>
        </el-form-item>
        <el-form-item
          prop="sort"
          label="排序："
          :rules="[$formRules.required(), $formRules.int2]"
        >
          <el-input
            v-model.number="formData.sort"
            placeholder="请输入数字，越小排序越靠前"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="resetForm">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FUpload from "@/components/upload";
import {
  getAdvertManageList,
  getAdvertTypeTree,
  insertAdvert,
  editAdvert,
  changeAdvertStatus,
  deleteAdvert
} from "@/api/appManage";

const formData = {
  advertTypeId: null, // 广告类型ID ,
  id: null,
  device: "2", //上架平台 2小程序 1app
  image: "", //广告画面 ,
  sort: null, //排序
  status: "", //状态：0-草稿，1-已上架，2-已下架 ,
  title: "", // 标题 ,
  url: "" //地址/路径
};
export default {
  components: {
    FUpload
  },
  data() {
    return {
      queryData: {
        pageNum: 1,
        pageSize: 5,
        advertTypeId: null,
        device: null,
        status: "" //0:草稿,1:上架,2:下架
      },
      formData: JSON.parse(JSON.stringify(formData)),
      status: {
        0: "草稿",
        1: "上架"
      },
      list: [],
      pageSizes: [5, 10, 20, 30, 50],
      total: 0,
      toggleEditDialog: false,
      advertTypeTree: [], //广告类型
      distributionFlag: false // 标识是否是分销平台，默认为false
    };
  },
  created() {
    this.getAdvertTypeTree();
    this.getAdvertManageList();
    // 判断是否为分销平台
    this.distributionFlag = this.$route.path.includes("distribution");
  },
  methods: {
    async deleteAdvert(id) {
      //删除
      const res = await deleteAdvert({ id });
      if (res) {
        this.$showSuccess("删除成功");
        this.getAdvertManageList();
      }
    },
    resetForm() {
      //重置表单
      this.toggleEditDialog = false;
      this.formData = this.$deepClone(formData);
      this.$refs.form.resetFields();
    },
    async getAdvertTypeTree() {
      //广告位类型
      const res = await getAdvertTypeTree();
      if (res) {
        this.advertTypeTree = res;
      }
    },
    async getAdvertManageList() {
      //广告列表
      const res = await getAdvertManageList(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    },
    search() {
      this.queryData.pageNum = 1;
      this.getAdvertManageList();
    },
    async changeAdvertStatus(id, status) {
      //发布 下架
      const res = await changeAdvertStatus({ id, status });
      if (res) {
        this.getAdvertManageList();
      }
    },
    showEdit(item) {
      //点击编辑
      this.formData = this.$deepClone(item);
      this.toggleEditDialog = true;
    },
    bannerImageUploadSuccess(fileList) {
      //上传banner成功
      this.formData.image = fileList[0].url;
      this.$refs.form.clearValidate("image");
    },
    submit() {
      //提交表单
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        const fn = this.formData.id ? editAdvert : insertAdvert;
        this.formData.url = this.formData.url.trim();
        const res = await fn(this.formData);
        if (res) {
          this.$showSuccess("提交成功");
          this.resetForm();
          this.getAdvertManageList();
        }
      });
    }
  }
};
</script>

<style lang="scss">
.banner-manage {
  .banner-uploader {
    margin-top: 10px;
    .picture-uploader-type2 {
      width: 300px;
      .text,
      .img {
        width: 280px;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.tips {
  color: #999;
  margin-top: 8px;
}
.filter {
  padding-top: 20px;
  .select {
    width: 150px;
    margin-right: 10px;
  }
}
.base-info {
  display: flex;
  .cover {
    width: 130px;
    height: 100px;
    border-radius: 5px;
  }
  .text {
    height: 100px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .sub {
      color: $color9;
    }
  }
}
</style>
